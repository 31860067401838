// Insert your styling here.
.footer {
  background-color: #ededed;
  color: @text-color;
  padding-bottom: 0;
  padding-top: 70px;
  margin-top: 0;
  .block-bean {
    margin-bottom: 30px;
    text-align: center;
  }
  .block-title {
    margin-top: 0;
    font-size: 43px;
    font-weight: normal;
    color: @brand-primary;
    @media (max-width: @screen-xs-max){
      font-size: 32px;
    }
  }

  #meddelande-entityform-edit-form {
    .form-group {
      margin-bottom: 0;
    }
  	.form-type-textfield {
      font-size: 13px;
  		width: 100%;
  		border-bottom: 1px solid #fff;
      margin-bottom: 5px;
  		.form-control {
        background-color: transparent;
  			border: 0 none;
  			box-shadow: none;
        font-style: italic;
        display: inline-block;
        width: 80%;
        color: #fff;
  		}
  		label {
        font-size: 14px;
        color: #fff;
  			text-transform: uppercase;
        width: auto;
  			&:after {
  				content: ':';
  				position: relative;
  				left: 0px;
  			}
  		}
  	}
  	.form-type-textarea {
      font-size: 13px;
      padding-top: 10px;

  		.grippie {
  			display: none;
  		}
      .form-control {
        background-color: transparent;
        font-style: italic;
        border-color: #fff;
        color: #fff;
      }
  		label {
        font-size: 14px;
        color: #fff;
  			text-transform: uppercase;
  			&:after {
  				content: ':';
  				position: relative;
  				left: 0px;
  			}
  		}
  	}
  	.form-actions {
      margin-top: 15px;
  		text-align:right;
      .btn-primary {
        background-color: @brand-danger;
        border-color: @brand-danger;
        padding-left: 30px;
        padding-right: 30px;
      }
  	}
  }
  .view.view-sociallinks {
    font-size: 30px;
    a {
      color: #fff;
    }
  }
  .copyright {
    border-top: 1px solid #d0caca;
    color: #8c8782;
    font-style: italic;
    text-align: center;
    font-size: 14px;
    #block-bean-copyright {
      padding: 25px 0;
      margin: 0;
    }
  }
  #block-bean-email, #block-bean-ppettider, #block-bean-plats {
    .block-title {
      font-family: @font-family-base;
      font-size: 17px;
      font-weight: bold;
    }
  }
  #block-bean-footer-logo {
    text-align: center;
    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}
