// Insert your styling here.
.navbar {
  margin-bottom: 0;
}

#navbar {
  background-color: transparent;
  @media (min-width: @grid-float-breakpoint) {
    background-color: #fff;
  }
}

.navbar-default {
  text-transform: none;
  font-weight: bold;
  font-size: 16px;
  .navbar-nav {
    > li > a {
      text-align:center;
    }
  }
  @media (min-width: @grid-float-breakpoint) {
    //background-color: rgba(21,21,21,0.85);
    /*position: relative;
    top: 109px;
    z-index: 5;
    margin-top: -109px;*/
    .navbar-nav > li {
      > a {
        padding-top: 21px;
        padding-bottom: 21px;
        border-bottom: 3px solid transparent;
        text-transform: uppercase;
        &:hover {
          border-color: @brand-danger;
        }
      }
      &.active a {
        border-color: @brand-danger;
      }
    }
  }
}

.navbar-collapse {
  border: 0 none;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#block-locale-language {
  @media (min-width: @grid-float-breakpoint){
    position: relative;
    top: 21px;
    float: right;
  }
  .language-switcher-locale-url {
     margin-bottom: 0;
    li + li:before {
      content: "|";
      margin-right: 5px;
    }
    > li {
      display: inline;
      a {
        color: @text-color;
        text-decoration: none;
        &.active, &:hover {
          color: @brand-primary;
        }
      }
    }
  }
}


div.navbar-collapse.width.collapse,
div.navbar-collapse.width.collapse.in,
div.navbar-collapse.width.collapsing {
  width: 100% !important;
}


#navright {
  .navbar-nav {
    text-align: center;
    > li > a {
      .h1;
      color: @text-color;
      &:hover, &:focus, &.active {
        background-color: transparent;
        color: @brand-primary;
      }
    }
  }
  .language-switcher-locale-url {
    padding: 0;
    text-align: center;
    li a {
      .h1;
      color: @text-color;
      .active, &:focus, &:hover {
        color: @brand-primary;
      }
    }
  }
  padding-left: 15px;
  display: block;
  min-height: 100%;
  overflow: auto;
  position: fixed;
  font-size: 15px;
  top: 0px;
  width: 100%;
  z-index: 1000;
  color: #fff;
  background-color: rgba(255,255,255,.96);
  right: -100%;
  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s;
  &.expanded {
      right: 0;
  }
  .collapse {
    display:block;
  }
  .close-btn {
    position:relative;
    background-color: transparent;
    font-size: 42px;
    line-height: 1;
    &:hover {
      color: #fff;
    }
    &:focus {
      outline:none;
    }
  }
  #navbar-collapse {
    position:absolute;
    top: 50%;
    left: 0;
    right: 0;
    box-shadow:none;
    overflow:hidden;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.navbar-toggle {
  z-index: 10000;
  border: none;
  background-color: #fff !important;
  .icon-bar {
    width: 32px;
    height: 5px;
    transition: all 0.2s;
  }
}
.navbar-toggle[aria-expanded="true"]{
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
    color:@brand-primary;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 50%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 50%;
  }
}
