// Insert your styling here.
body {
  background: #ededed url('../images/bg.jpg?v=0002');
  overflow-x: hidden;
}

.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (min-width: 1200px) {
  .container {
    width: 874px;
  }
  .view-id-facebook.view-display-id-block.container {
    width: 1074px;
  }
}

h1, h2, .h1, .h2 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color:@brand-primary;
}
h1, .h1 {
  @media (max-width: @screen-xs-max){
    font-size: 32px;
  }
}

h3, h4 {
  font-weight: 700;
}

.btn {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
}

.btn-lg {
  padding: 18px 44px;
}

.main-container .block-title {
  font-family: @font-family-base;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  color: #846758;
  margin: 0;
  &:before, &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #dfd6d2;
    margin: auto 15px;
  }
}

img {
  max-width: 100%;
  height: auto;
}
.page-header {
  text-align: center;
  border: 0 none;
}


.read-more a {
  .btn;
  .btn-primary;
}

#page-header {
  text-align: center;
}
.not-logged-in.page-user {
  .main-container {
    max-width: 600px;
  }
}

#block-system-main {
  > .node {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
-moz-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
    > div {
      max-width: 680px;
      margin: 0 auto;
    }
    .field-name-angle-down {
      font-size: 50px;
      line-height: 1;
      margin-bottom: 50px;
      color:@brand-primary;
    }
    .field-name-field-content-image {
      text-align: center;
      margin-top: 30px;
      .img-responsive {
        margin: 0 auto;
      }
    }
  }
}


#page-header {
  .row();
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  .logo.navbar-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    z-index:5;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: inline-block;
    width: 100%;
  }
}

.not-front #page-header {
  .logo.navbar-btn {
    max-width: 590px;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-50%,-70%);
    -moz-transform: translate(-50%,-70%);
    -ms-transform: translate(-50%,-70%);
    -o-transform: translate(-50%,-70%);
    transform: translate(-50%,-70%);
  }
}

.page-user #page-header {
  .logo.navbar-btn {
    img {
      max-width: 100%;
    }
    position: relative;
  }
}


.header-bg {
  /*padding-bottom: 59.25%;*/
  height: 480px;
  background-position: center !important;
  .cover() !important;
}
.front .header-bg {
  height: 100vh;
}

.page-bg {
  height: 500px;
  background-position: center !important;
  .cover() !important;
}


#block-views-frontpage-slideshow-block {
  margin-bottom: -110px;
  .view-frontpage-slideshow {
    > .view-content {
      .views-field {
        &.views-field-field-link {
          position: absolute;
          top: 70%;
          line-height: 1;
          color: #fff;
          z-index: 5;
          text-align: center;
          left: 0;
          right: 0;
          padding: 15px;
          margin-left: auto;
          margin-right: auto;
          text-transform: uppercase;
          -webkit-transform: translateY(-70%);
          -moz-transform: translateY(-70%);
          -ms-transform: translateY(-70%);
          -o-transform: translateY(-70%);
          transform: translateY(-70%);
          .field-content {
            border: 2px solid #73181a;
            display: inline-block;
            padding: 5px;
          }
        }
      }
    }
  }
}
#block-views-lunch-block {
  background-color: #fff;
  -webkit-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
  -moz-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
  box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
  position: relative;
  z-index: 2;
  margin-bottom: 90px;
  @media (min-width: @screen-md-min) {
    padding-left: 60px;
    padding-right: 60px;
  }
  /* Lunch */
  .block-title {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .view-lunch {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px;
    > .view-footer {
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
    > .view-header {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      text-align: center;
    }
    > .view-content {
      text-align: center;
      .col-md-15, .lunch {
        margin-bottom: 30px;
        padding: 0 30px 30px;
        p {font-style: italic;}
      }
      .today {
        position:relative;
        border: 1px solid @brand-danger;
        .today-flag {
          display: inline-block;
          padding: 5px 15px;
          background-color: @brand-danger;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          position: absolute;
          left: -1px;
          top: -40px;
        }
      }
      h2 {
        font-weight: bold;
        display: inline-block;
        color: @text-color;
      }
      .date {
        font-weight: bold;
        .h2;
        color: @text-color;
      }
    }
    .view-footer {
      clear: both;
    }
  }
}
/* Salladsbar */

.view-salladsbar {
  > .view-content {
    .node {
      white-space: nowrap;
      text-align: center;
    }
    .field {
      &.field-name-title-field {
        display: inline-block;
        vertical-align: middle;
        margin-right: 30px;
        font-size: 30px;
      }
      &.field-name-body {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        position: relative;
        max-width: 65%;
        white-space: normal;
        font-size: 18px;
        font-style: italic;
        padding: 20px;
        border: 1px solid #a59a91;
        border-top-left-radius: 25px 50px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px 50px;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        &:before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 10px 20px 10px 0;
          border-color: transparent #a59a91;
          display: block;
          width: 0;
          z-index: 1;
          left: -20px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 9px 19px 9px 0;
          border-color: transparent #e2dad4;
          display: block;
          width: 0;
          z-index: 1;
          left: -18px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
  }
}


/* News */

#block-views-news-block {
  background: url('../images/news-bg.jpg') no-repeat center;
  padding-bottom: 100px;
  .cover();
  .view-news {
		.view-content .flexslider {
      margin: 0;
	  	.flex-viewport {
	  		  max-width: 1140px;
	  		  margin: 0 auto;
	    }
		  .flex-direction-nav a {
        line-height: 40px;
		  }
	 	  .slides > li {
        margin: 0;
	 	   > div {
         min-height: 480px;
         color: #fff;
         padding: 15px 30px;
         background-color: rgba(0,0,0,.8);
			   margin-right: 15px;
			   margin-left: 15px;
         .field {
           &.field-name-title-field {
             h3 {
               font-size: 24px;
               font-style:italic;
             }
           }
           &.field-name-post-date {
             color: #f73030;
             text-transform: uppercase;
             font-size: 14px;
             font-weight: bold;
             margin-bottom: 10px;
             &:after {
               content: "";
               height: 1px;
               width: 100%;
               display: block;
               margin-top: 10px;
               background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#fff), to(transparent));
               background-image: -webkit-linear-gradient(left, #fff, transparent);
               background-image: -moz-linear-gradient(left, #fff, transparent);
               background-image: -o-linear-gradient(left, #fff, transparent);
            }
           }
           &.field-name-body {
             font-size: 16px;
           }
         }
       }
     }
   }
  }
}

/* Flexslider */
.flexslider {
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

/* Middagsboxen */
#block-views-middagsboxen-block {
  padding-bottom: 80px;
.view-middagsboxen {
  .view-header {
    font-size: 22px;
    font-style: italic;
    text-align: center;
    margin-bottom: 30px;
  }
  .view-content {
    .field {
      font-size: 16px;
    }
  }
}
}


/* Sundays */

#block-views-sundays-block {
  padding-bottom: 100px;
  background: url('../images/sundays-bg.jpg') no-repeat center;
  .cover();
  > .view > .view-content {
    .field {
      font-size: 24px;
    }
    max-width: 780px;
    margin: 0 auto;
    text-align:center;
    background: rgba(0,0,0,.8);
    color: #fff;
    padding: 60px 100px;
  }
}


/* Catering */

.view-node-gallery {
  .view-content {
    > div {
      > span {
        .img-responsive {
          max-width: 248px;
          width: 100%;
        }
        .make-xs-column(6);
        margin-bottom: 30px;
      }
      > a {
        &.colorbox-inline {
          display: none;
        }
        .make-xs-column(6);
        margin-bottom: 30px;
      }
    }
  }
}
#block-views-catering-block {
  .view-catering {
    .view-content {
      .field {
        &.field-name-body {
          padding: 50px 30px;
          font-size: 18px;
        }
      }
    }
  }
}

#block-bean-bokacatering {
  text-align: center;
  padding: 300px 0;
  background: url('../images/DSC_2529.jpg?v=001') no-repeat center 70%;
  .cover();
  margin-top: -190px;
  margin-bottom: -190px;
  position: relative;
  .field-name-field-link {
    .field-item {
      border: 2px solid #73181a;
      display: inline-block;
      padding: 5px;
    }
  }
}

#block-views-facebook-block {
  //background: url('../images/_1022666-2.jpg') no-repeat center;
  padding-bottom: 90px;
  position: relative;
  .cover();
  .view-facebook {
    .fb-wrapper {
      background-color: #fff;
      overflow: auto;
      -webkit-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
      -moz-box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
      box-shadow: 4px 4px 17px 5px rgba(0,0,0,0.12);
      @media (min-width: @screen-md-min) {
        padding-left: 60px;
        padding-right: 60px;
      }
    }
    .view-header {
      padding-top: 40px;
      padding-bottom: 30px;
      text-align: center;
      position: relative;
    }
    .view-content {
      font-size: 14px;
      > div {
        max-width: 388px;
        margin: 0 auto 80px;
        .from {
          clear: both;
          margin-top: 10px;
          margin-bottom: 15px;
          display: table;
          min-height: 74px;
        }
        .views-field-field-profile-pic {
          width: 50px;
          display: table-cell;
          vertical-align: top;
        }
        .views-field-created {
          display: table-cell;
          padding-top: 5px;
        }
        .views-field-field-fb-image {
          margin-bottom: 15px;
        }
        .views-field-guid {
          margin-top: 15px;
          float: left;
          a {
            font-weight: bold;
          }
        }
        .views-field-field-fb-link-1 {
          margin-top: 15px;
          float:right;
          a {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.view-entityform-view {
  h2 {
    .h1;
    text-align: center;
  }
}

#meddelande-entityform-edit-form {
  .pre-instructions {
    margin-bottom: 30px;
  }
  .form-group {
    margin-bottom: 0;
  }
  #edit-field-namn1 {
    @media (min-width: @screen-sm-min){
      padding-left: 0;
      padding-right: 7.5px;
      width:50%;
      float: left;
    }
  }
  #edit-field-phone {
    @media (min-width: @screen-sm-min){
      padding-left: 7.5px;
      padding-right: 0;
      width:50%;
      float: left;
    }
  }
  #edit-field-e-mail {
    &:before {
      content: "";
      display: table;
      clear: both;
    }
  }
  .form-type-textfield {
    font-style: italic;
    font-size: 13px;
    width: 100%;
    border-bottom: 1px solid #979c9a;
    margin-bottom: 5px;
    .form-control {
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      font-style: italic;
      display: inline-block;
      width: 80%;
      color:@text-color;
    }
    label {
      font-size: 14px;
      color:@text-color;
      width: auto;
      &:after {
        content: ':';
        position: relative;
        left: 0px;
      }
    }
  }
  .form-type-textarea {
    font-size: 13px;
    padding-top: 10px;
    font-style: italic;
    .grippie {
      display: none;
    }
    .form-control {
      background-color: transparent;
      font-style: italic;
      border-color: #979c9a;
      color: @text-color;
    }
    label {
      font-size: 14px;
      color: @text-color;
      &:after {
        content: ':';
        position: relative;
        left: 0px;
      }
    }
  }
  .form-actions {
    margin-top: 15px;
    .btn-primary {
      .btn-lg;
      .btn-block;
    }
  }
}


#block-views-header-image-block {
  margin-bottom: -110px;
}

#block-views-map-block {
  margin-top: -80px;
}

#block-views-page-image-block {
  margin-top: -80px;
  min-height: 200px;
}


.gm-style-iw > div > div {
  overflow: hidden !important;
}

.view-employees {
  padding-top: 50px;
  text-align: center;
  .views-bootstrap-grid-plugin-style {
    > .row {
      > div {
        margin-bottom: 30px;
        .img-responsive {
          margin: 0 auto;
        }
      }
    }
  }
}

.node-type-permanent-page {
  #page-header {
    min-height: 350px;
  }
}
